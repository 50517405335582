import React, {useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import LandingPageHeader from '../landingPageHeader'
import LandingPageStoryHome from './landingPageStoryHome'
import LandingPageStoryTeam from './landingPageStoryTeam'
import LandingPageStoryJoinUs from './landingPageStoryJoinUs'
import LandingPageStoryOpeningJobs from './landingPageStoryOpeningJobs'
import LandingPageStoryContact from './landingPageStoryContact'
import LandingPageFooter from '../landingPageFooter'
import LandingPageStoryAdvisors from './landingPageStoryAdvisors'
import "../../../css/LandingPageStory.css"


const Story = ({changeView}) => {
    
    return (
        <div className="landingPage-story-outerContainer">
        <LandingPageHeader />
        <LandingPageStoryHome/>
        <LandingPageStoryTeam />
        <LandingPageStoryAdvisors />
        <LandingPageStoryJoinUs />
        <LandingPageStoryOpeningJobs />
        <LandingPageStoryContact />
        <LandingPageFooter changeView = {changeView}/>
    </div>
    )
    
}

export default Story
import React from 'react'
import Chris_Headshot from '../../../images/landingPage/Chris_Headshot.png'
import Mara_Headshot from '../../../images/landingPage/Mara_Headshot.png'
import Salman_Headshot from '../../../images/landingPage/Salman Ahmad.jpg'
import LinkedIn_Icon from '../../../images/landingPage/LinkedIn_Icon.png'

const LandingPageStoryTeam = () => {
    return (
        <div id="team" className="landingPage-story-team">
            <h5 className="landing-page-subtitle">Meet The Team</h5>
                <svg height = "50px" >
                    <line class="landingPageContent-second-line" x1="100" x2="200" y1="5" y2="5" />
                </svg>
            <div className="landingPage-col3">
                <div className="landingPage-headShot-text-link">
                    <img className="headshot" src={Chris_Headshot} />
                    <h6>Chris Ujimoto</h6>
                    <p>CEO & Founder</p>
                    <a href="http://linkedin.com/in/chris-ujimoto-7b4225174"><img className="linkedin-icon" src={LinkedIn_Icon}/></a>
                </div>
                <div className="landingPage-headShot-text-link">
                    <img className="headshot" src={Mara_Headshot} />
                    <h6>Mara Finkelstein</h6>
                    <p>Product Development & Operations</p>
                    <a href="http://linkedin.com/in/marafinkelstein"><img className="linkedin-icon" src={LinkedIn_Icon}/></a>
                </div>
                <div className="landingPage-headShot-text-link">
                    <img className="headshot" src={Salman_Headshot} />
                    <h6>Salman Ahmad</h6>
                    <p>Software Architect</p>
                    <a href="http://linkedin.com/in/salman-ahmad-897ba252"><img className="linkedin-icon" src={LinkedIn_Icon}/></a>
                </div>
            </div>
        </div>
    )
}

export default LandingPageStoryTeam
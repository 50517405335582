import React from 'react';
import LandingPageHeader from '../landingPageHeader';
import LandingPageFooter from '../landingPageFooter';
import PrivacyContent from '../Terms/privacyContent';

const Privacy = () => {
    
    return(
        <>
        <LandingPageHeader/>
        <PrivacyContent/>
        <LandingPageFooter/>
        </>
    );
    
};

export default Privacy;
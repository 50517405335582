import React, {useState} from 'react'
import logo from '../../../images/landingPage/EduShare_Logo_Homepage_Big.png'
import ThankYou from '../Overlay/ThankYou'
import {Form, Container} from 'react-bootstrap'
import { Modal } from 'react-responsive-modal';


const Contactus = ({show, setShow}) => {
    
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const [SuccessShow, setSuccessShow] = useState(false);
    const handleSuccessShow = () => {
        handleClose();
        setSuccessShow(true);
    }
    const handleSuccessClose = () => setSuccessShow(false);

    return (
        <>
        <Modal open={show} onClose={handleClose} dialogClassName='modal-90w' center classNames={{modal: 'customModal',}} >
                    
                    <Container>
                        <div className='row justify-content-center'>
                            <img src={logo} height="200"/>
                        </div>

                        <div className='row justify-content-center'>
                            <p className='t2'>Contact Us</p>
                        </div>

                       

                        <div className='row justify-content-center'>
                            <div className = "col-md-6">
                                <Form>
                                    <Form.Group controlId="formBasicFullName">
                                        <Form.Label>Full Name</Form.Label>
                                        <Form.Control type="name" placeholder="Full Name" />
                                    </Form.Group>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label>Email address</Form.Label>
                                        <Form.Control type="email" placeholder="Enter email" />
                                    </Form.Group>
                                    <Form.Group controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>Message</Form.Label>
                                        <Form.Control as="textarea" rows="5" />
                                    </Form.Group>
                                </Form>
                                
                            </div>
                        </div>
                        <center><button className="landingPageHeader-button2" onClick={handleSuccessShow}>Submit</button></center>
                    </Container>
                </Modal>
                <ThankYou show={SuccessShow} setShow={setSuccessShow} />
        </>
    );
}

export default Contactus;
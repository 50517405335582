import React from 'react'
import logo from '../../../images/landingPage/EduShare_Logo_Homepage_Big.png'
import check from '../../../images/landingPage/check-circle-solid.svg'
import "../../../css/OverlayJobDetails.css"
import {Container} from 'react-bootstrap'
import { Modal } from 'react-responsive-modal';


const SubscribeSuccess = ({show, setShow}) => {
    
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    return (
        <Modal open={show} onClose={handleClose} dialogClassName='modal-90w' center classNames={{modal: 'customModal',}} >
                    
                    <Container>
                            <div className='row justify-content-center'>
                                <div className="row">
                                    <img src={logo} height="200"/>
                                </div>
                                
                            </div>

                            <div className='row justify-content-center'>
                                <p className='t2'>Thank  you!</p>
                            </div>

                            <div className='row justify-content-center'>
                                <div className='col-md-12 text-center'>
                                    <p className='t14'>
                                    Thank you for subscribing!
                                    </p>
                                </div>
                            </div>

                            <div className='row justify-content-center'>
                                <img src={check} />
                            </div>
                    
                       

                         </Container>
                </Modal>
    );
}

export default SubscribeSuccess;
import React, { useState, useEffect } from 'react'
import logo from '../../../images/landingPage/EduShare_Logo_Homepage_Big.png'
import SubscribeSuccess from '../Overlay/SubscribeSuccess'
// import "../../../css/OverlayJobDetails.css"
import { Form, Container } from 'react-bootstrap'
import { Modal } from 'react-responsive-modal'
import HubspotForm from 'react-hubspot-form'


const Subscribe = ({ show, setShow }) => {

    // const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);


    const [SuccessShow, setSuccessShow] = useState(false);
    // const handleSuccessShow = () => {
    //     handleClose();
    //     setSuccessShow(true);
    // }
    // const handleSuccessClose = () => setSuccessShow(false);

    // useEffect(() => {
    //     const script = document.createElement('script');

    //     script.src = "https://cujimoto.activehosted.com/f/embed.php?id=3";
    //     script.type = "text/javascript";
    //     if(show) document.body.appendChild(script);
    //     else{
    //         var list = document.getElementsByClassName('_form_3');
    //         while(list[0]) list[0].remove();
    //     }
    // });
    return (
        <>
            <Modal open={show} onClose={handleClose} dialogClassName='modal-90w' center classNames={{ modal: 'customModal', }} >

                <Container>
                    <div className='row justify-content-center'>
                        <img src={logo} height="200" />
                    </div>

                    <div className='row justify-content-center'>
                        <p className='t2'>Let's Get Started</p>
                    </div>

                    <div className='row justify-content-center'>
                        <div className='col-md-8 text-center'>
                            <p className='t14'>
                                Subscribe now to join us as we grow our offering to support all students from kindergarten through high school.
                                </p>
                        </div>
                    </div>

                    {/* <div className='row justify-content-center'> */}
                    {/* <div className = "col-md-6"> */}
                    {/* <Form>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label>Email address</Form.Label>
                                        <Form.Control type="email" placeholder="Enter email" />
                                    </Form.Group>
                                </Form> */}
                    {/* </div>
                        </div> */}

                    
                    <div className="row justify-content-center">
                        <HubspotForm portalId='8679880' formId='2500fdfd-b89e-4b00-b988-9e3ec37ece8c' />
                    </div>
                    
                    {/* <script src="https://cujimoto.activehosted.com/f/embed.php?id=3" type="text/javascript" charset="utf-8"></script> */}
                    {/* <center><button className="landingPageHeader-button2" onClick={handleSuccessShow}>Subscribe</button></center> */}
                </Container>
            </Modal>
            {/* <ThankYou show={ThankYouShow} setShow={setThankYouShow}/> */}
            <SubscribeSuccess show={SuccessShow} setShow={setSuccessShow} />
        </>
    );
}

export default Subscribe;
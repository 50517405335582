import LinkedIn_Icon from '../../../images/landingPage/LinkedIn_Icon.png'
import React from 'react'


const LandingPageStoryAdvisor = ({name, title, link, status}) => {
    return (
        <div className="landingPage-advisor">
            {(name)? <h6>{name}</h6> : null}
            {(title)? <p>{title}</p> : null}
            {(status)? <p>{status}</p> : null}
            {(link) ? 
                <a href={link}><img className="linkedin-icon" src={LinkedIn_Icon}/></a> :
                null
            }
        </div>
    )
}

export default LandingPageStoryAdvisor
import LinkedIn_Icon from '../../../images/landingPage/LinkedIn_Icon.png'
import React from 'react'
import LandingPageStoryAdvisor from './landingPageStoryAdvisor'
import { map } from 'jquery'

const advisors = [
    {
        "name": "Kevin Rose",
        "title": "Software Engineering Manager",
        "status": "Employed at Amazon",
        "link": "http://linkedin.com/in/kevinrose36"
    },
    {
        "name": "Maury Dergousoff",
        "title": "Business Consultant",
        "status": "Employed at TELUS Business",
        "link": "http://linkedin.com/in/maury-dergousoff-5a601a31"
    },
    {
        "name": "Vivienne Kraus",
        "title": "French Teacher",
        "status": "Employed at Bayview Glen Independent School"
    },
    {
        "name": "Jessica Nordahl",
        "title": "Teacher-Librarian",
        "status": "Employed at the Toronto French School (TFS)",
        "link": "http://linkedin.com/in/jessica-nordahl-687a1288"
    },
    {
        "name": "Michael Sacco",
        "title": "Founder, ChocoSol",
        "status": "Social Venture Entrepreneur",
        "link": "http://linkedin.com/in/michael-sacco-b305685"
    },
    {
        "name": "Andrew Smee",
        "title": "Partner, Financial Services",
        "status": "Employed at PwC",
        "link": "http://linkedin.com/in/andrewsmee"
    },
    {
        "name": "Gennady Ferenbok",
        "title": "Vice-President, General Counsel and Secretary",
        "status": "Employed at Coca-Cola Canada Bottling Limited",
        "link": "http://linkedin.com/in/gennady-ferenbok-125217"
    },
    {
        "name": "Rachael Ferenbok",
        "title": "Senior Legal Counsel, Office of the Vice-President and Provost",
        "status": "Employed at the University of Toronto",
        "link": "http://linkedin.com/in/rachaelmendicino"
    },
    {
        "name": "Derek Tse",
        "title": "Managing Editor",
        "status": "Employed at the Toronto Sun",
        "link": "http://linkedin.com/in/derek-tse-4a9416142"
    },
    {
        "name": "Kien Nguyen",
        "title": "Director of Institutional Sales",
        "status": "Employed at Dream Unlimited",
        "link": "https://www.linkedin.com/in/kienkeen/"
    }
]

const LandingPageStoryAdvisors = () => {
    return (
        <div className="landingPage-story-advisors">
            <h5 className="landing-page-subtitle">Advisors</h5>
                <svg height = "50px" >
                    <line class="landingPageContent-second-line" x1="100" x2="200" y1="5" y2="5" />
                </svg>
            <div className="landingPage-col3">
                {
                    advisors.map((advisor,i) => 
                        <LandingPageStoryAdvisor key={i} {...advisor}  />
                    )
                }
            </div>
        </div>
    )
}

export default LandingPageStoryAdvisors
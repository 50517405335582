import React from 'react'
import StudentsJumping from '../../../images/landingPage/StudentsJumping_Icon.png'


const LandingPageStoryHome = () => {
    return (
        <div className="landingPage-col2">
            {/* <div className="landingPage-text left-text"> */}
            <div id="aboutus" className="landingPage-text">
            {/* about us content */}
                <h1>About Us</h1>
                <p>The problems that exist in education were only accentuated by the pandemic.  
                Teachers need a means to share their knowledge and to create lessons that are 
                engaging and educational as well as finding a way to collaborate with other educators
                 outside of their region.  Students need to work on their study skills and find ways to learn topics 
                 that are challenging.  
                 Parents want a way to make sure their child is learning.  
                 EduShare aims to solve all of these concerns.  Collaboration.  Sharing.  Curation.  Gamification.  Evaluation.  EduShare will bring all of these together in one platform. </p>
            </div>
            <div className="landingPage-img">
                <img src={StudentsJumping} alt= "StudentsJumping" />
                {/* credit */}
                <span className="picture-credited">Designed by Freepik</span>
            </div>
        </div>
    )
}


export default LandingPageStoryHome
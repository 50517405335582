import React, {useState} from 'react'
import JobDetailsOverlay from '../Overlay/JobDetails'

const LandingPageStoryOpeningJobs = () => {
    const [show, setShow] = useState('false')
    const [title, setTitle] = useState('')
    const [requiredSkills, setRequiredSkills] = useState([])
    const [preferredSkills, setPreferredSkills] = useState([])
    const [benefits, setBenefits] = useState([])

    const showOverlay = (title, required_skills, preferred_skills, benefits) => {
        setShow('true')
        setTitle(title)
        setRequiredSkills(required_skills)
        setPreferredSkills(preferred_skills)
        setBenefits(benefits)
        // console.log(show);
    }

    return (
        <div>
            <div className="landingPage-story-openingJobs">
            <h5 className="landing-page-subtitle">Current Openings</h5>
                <svg height = "50px" >
                    <line class="landingPageContent-second-line" x1="100" x2="200" y1="5" y2="5" />
                </svg>
            <div className="landingPage-col1">
            <p>please contact <a href="mailto:Info@edushare.ca">Info@edushare.ca</a> for current job opportunities.</p>
                {/* <div className="landingPage-story-department">
                    <h5>Engineering</h5>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    <p className="p-link" 
                    onClick={() => showOverlay("Senior Software Engineer - Toronto, ON", ["1", "2", "3"], ["1", "2", "3"], ["1", "2", "3"])}>
                    Senior Software Engineer - Toronto, ON</p>
                    <p className="p-link"
                    onClick={() => showOverlay("Front End Developer - Toronto, ON", ["1", "2", "3"], ["1", "2", "3"], ["1", "2", "3"])}>
                    Front End Developer - Toronto, ON</p>
                </div>

                <div className="landingPage-story-department">
                    <h5>Design</h5>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    <p className="p-link"
                    onClick={() => showOverlay("Senior Product Designer - Toronto, ON", ["1", "2", "3"], ["1", "2", "3"], ["1", "2", "3"])}>
                    Senior Product Designer - Toronto, ON</p>
                    <p className="p-link"
                    onClick={() => showOverlay("UX Researcher - Toronto, ON", ["1", "2", "3"], ["1", "2", "3"], ["1", "2", "3"])}>
                    UX Researcher - Toronto, ON</p>
                </div> */}
            </div>
        </div>
        <JobDetailsOverlay show={show} title={title} requiredSkills={requiredSkills} preferredSkills={preferredSkills} benefits={benefits} setShow={setShow}/>
        </div>
        
        
    )
}

export default LandingPageStoryOpeningJobs



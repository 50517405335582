import React, {useState} from 'react'
import logo from '../../images/landingPage/EduShare_Logo_Homepage_Big.png'
import '../../css/LandingPageHeader.css'
import 'react-responsive-modal/styles.css';
import Subscribe from './Overlay/Subscribe'



// header for landing page

const LandingPageHeader = () => {
    const [show, setShow] = useState(false);
    

    return(
        <>
            <div className="landingPageHeader-outerContainer">
                <div className="landingPageHeader-innerContainer">
                    <div className="landingPageHeader-imageContainer">
                        <a href="/"><img src={logo} alt="logo" /></a>
                    </div>
                    <div className="landingPageHeader-buttonGroup">
                        <a href="/story"><button className="landingPageHeader-button1">Our Story</button></a>
                        <button className="landingPageHeader-button2" onClick={() => setShow(true)}>Subscribe</button>
                    </div>
                </div>
                <Subscribe show={show} setShow={setShow}/>
            </div>
        </>
    )
}

export default LandingPageHeader
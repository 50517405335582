import React, {useEffect} from 'react'

import HubspotForm from 'react-hubspot-form'


const LandingPageStoryContact = () => {
    // useEffect(() => {
    //     const script = document.createElement('script');

    //     script.src = "https://cujimoto.activehosted.com/f/embed.php?id=1";
    //     script.type = "text/javascript";

    //     document.body.appendChild(script);
    //     // console.log("add child!" + script.src);
    //     return () => {
    //         // console.log("remove child!" + script.src);
    //         document.body.removeChild(script);
    //     }
    // });

    return (
        <div className="landingPage-story-contact">
            <h5 className="landing-page-subtitle">Get in Touch</h5>
            <svg height = "50px" >
                <line class="landingPageContent-second-line" x1="100" x2="200" y1="5" y2="5" />
            </svg>
                
            <div className="width-50-percentage">
                <HubspotForm portalId='8679880' formId='4b034057-f19f-42c9-b350-99d39295d795'/>
            </div>
                {/* <div class="_form_1"> 
                </div>
                <script src="https://cujimoto.activehosted.com/f/embed.php?id=1" type="text/javascript" charset="utf-8"></script> */}
                
            {/* replace by activecamaign */}
            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
            {/* <form action="mailto:cujimoto@gmail.com" method="post" enctype="text/plain">
                <div>
                    <span>Full Name</span>
                    <input type="text"></input>
                </div>
                <div>
                    <span>Email</span>
                    <input type="email"></input>
                </div>
                <div>
                    <span>Phone</span>
                    <input type="number"></input>
                </div>
                <div>
                    <span>Position</span>
                    <input type="text"></input>
                </div>
                <div>
                   <span>Tell us why you're interested</span>
                    <textarea rows="6" cols="30" className="multiline-input"></textarea>
                </div>
                <button type="submit">Submit</button>
            </form> */}
        </div>
    )
}

export default LandingPageStoryContact
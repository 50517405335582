import React, {useEffect }  from 'react';
import '../css/App.css';
import {useLocation, BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import LandingPage from './landingPage/landingPage'
import Story from './landingPage/Story/Story.js'
import Terms from './landingPage/Terms/terms'
import 'bootstrap/dist/css/bootstrap.css';
import Privacy from './landingPage/Terms/privacy';

function App() {

  const location = useLocation();
    useEffect(() => {
        // console.log("path name", location.hash);
        let currentHash = location.hash.slice(2);
        // console.log("hash", currentHash);
        const element = document.getElementById(`${currentHash}`);
        // console.log(element)
        if (element !== null && element !== undefined) {
            element.scrollIntoView({
                behavior: "smooth"
            });
        } 
    });

    const changeView = (id) => {
        window.location.href = `#/${id}`;
        const element = document.getElementById(`${id}`);
        if (element !== null && element !== undefined) {
            element.scrollIntoView({
                behavior: "smooth"
            });
        }
    };

  return (
    <React.Fragment>
      <Router>
              <Switch>
                  // Route for landing page
                  <Route path="/story" component={() => <Story changeView={changeView}/>} />
                  <Route exact path="/terms" component={Terms} />
                  <Route exact path='/privacy' component={Privacy} />
                  <Route path="/" component={() => <LandingPage changeView={changeView} />} />
              </Switch>
          </Router>
    </React.Fragment>
          
  );
}

export default withRouter(App);

import React, {useEffect} from 'react'
import "../../../css/OverlayJobDetails.css"
// class JobDetailsOverlay extends Component {
//     constructor(props) {
//         super(props)
//         this.state = {
//             title
//         }
//     }
// }


const JobDetailsOverlay = ({show, title, requiredSkills, preferredSkills, benefits, setShow}) => {
    let overlay;
    const closeOverlay = (e) => {
        e.preventDefault()
        // console.log(overlay)
        // overlay.style.display = "none";
        setShow('false');
    }

    useEffect(() => {
        if (overlay && show === 'true') {
            overlay.style.display = "inline";
        } else if (overlay) {
            overlay.style.display = "none";
        }
    }, [show])

    return (
        <div className="job-details-container" ref={e => overlay = e}>
            <div className="job-detals-overlay">
            <div className="job-title">
                <h5>{title}</h5>
            </div>
            
            <div className="about-company">
                <h6>About EduShare</h6>
            </div>
            <div className="required-skills">
                <h6>Required Qualificatons</h6>
                <ul>
                    {
                        (requiredSkills).map((requiredSkill, i) => (<li key={i}>{requiredSkill}</li>))
                    }
                </ul>
            </div>
            <div className="preferred-skills">
                <h6>Preferred Qualifications</h6>
                <ul>
                    {
                        (preferredSkills).map((preferredSkill, i) => (<li key={i}>{preferredSkill}</li>))
                    }
                </ul>
            </div>
            <div className="benefits">
                <h6>Benefits and Perks</h6>
                <ul>
                    {
                        (benefits).map((benefit, i) => (<li key={i}>{benefit}</li>))
                    }
                </ul>     
            </div>
            <button onClick={closeOverlay}>Close</button>
            </div>
        </div>
        
    )
}

export default JobDetailsOverlay
import React, {useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import LandingPageHeader from './landingPageHeader'
import LandingPageContent from './landingPageContent'
import LandingPageFooter from './landingPageFooter'
import '../../css/LandingPage.css'

// landing page component
const LandingPage = ({changeView}) => {

    return (<div>
        <div className="landingPage-outerContainer">
        <LandingPageHeader />
        <LandingPageContent changeView={changeView}/>
        <LandingPageFooter changeView={changeView}/>
        </div>
       
    
    </div>)
    
}

    


export default LandingPage

import React from 'react'
import JoinUs_Icon from '../../../images/landingPage/JoinUs_Icon.png'

const LandingPageStoryJoinUs = () => {
    return (
        <div className="landingPage-story-join">
            <h5 id="careers" className="landing-page-subtitle">Join Us</h5>
                <svg height = "50px" >
                    <line class="landingPageContent-second-line" x1="100" x2="200" y1="5" y2="5" />
                </svg>
            <div className="landingPage-col2">
                <div className="landingPage-img">
                    <img src={JoinUs_Icon}/>
                    {/* credit */}
                    <span className="picture-credited">Designed by Freepik</span>
                </div>
                <div className="landingPage-text">
                <h5>Join our team!</h5>
                <p>Are you passionate about learning?  Want to be on the cutting edge of educational technology?  EduShare is looking for high-performing individuals to join our team.  Please email info@edushare.ca for more information.</p>
                </div>
            </div>
        </div>
    )
}


export default LandingPageStoryJoinUs
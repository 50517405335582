import React, {useState} from 'react'
import logo from '../../images/landingPage/EduShare_Logo_Homepage_Big.png'
import Contactus from './Overlay/Contactus'
import '../../css/LandingPageFooter.css'
import { Link } from 'react-router-dom';

// Footer for landing page
function LandingPageFooter({changeView}){

    const [show, setShow] = useState(false);

    // const changeViewForFooter = (id) => {
    //     window.location.href = `#/${id}`;
    //     const element = document.getElementById(`${id}`);
    //     if (element !== null && element !== undefined) {
    //         element.scrollIntoView({
    //             behavior: "smooth"
    //         });
    //     }
    // };

    return(
        <>
        <div className="footer">
            <div className="row justify-content-center ">             
                <div className="col-12 offset-1 col-sm-5">
                    <div className='row'>
                        <img src={logo} width="15%" height="18%" />
                        <p className='t2 mt-1 ml-4'>EduShare</p>
                        
                    </div>
                    
                    
                </div>
                <div className='col-12 col-sm-2'/>
                <div className="col-12 col-sm-4 mt-3">
                    <div className='row'>
                        <div className='col-12 col-sm-3'>
                            <p className='t11'>Products</p>
                            <ul className="list-unstyled mt-4">
                                <li><Link to="/#/student" onClick={() => changeView("student")} className='t12'>Student</Link></li>
                                <li><Link to="/#/teacher" onClick={() => changeView("teacher")} className='t12'>Teachers</Link></li>
                                <li><Link to="/#/parent" onClick={() => changeView("parent")} className='t12'>Parents</Link></li>
                            </ul>
                        </div>
                        <div className='col-12 col-sm-3'>
                            <p className='t11'>Company</p>
                            <ul className="list-unstyled mt-4">
                                <li><Link to='/story/#/aboutus' onClick={() => changeView("aboutus")} className='t12'>About Us</Link></li>
                                <li><Link to='/story/#/careers' onClick={() => changeView("careers")} className='t12'>Careers</Link></li>
                                <li><Link to='/story/#/team' onClick={() => changeView("team")} className='t12'>Team</Link></li>
                            </ul>
                        </div>
                        <div className='col-12 col-sm-3'>
                            <p className='t11'>Support</p>
                            <ul className="list-unstyled mt-4">
                                <li><Link onClick={() => setShow(true)} className='t12'>Contact Us</Link></li>
                                <li><Link to = '/terms' className="t12" >Terms</Link></li>
                                <li><Link to = '/privacy' className='t12'>Privacy</Link></li>
                            </ul>
                        </div>
                    </div>
                   
                </div>
            </div>
            <div className="row mt-20 mr-20">   
                    <hr className='style-one'/>
                    <div className="col-12  offset-1 col-sm-4 text-left">
                        <p>
                            <a href="https://www.linkedin.com/company/edusharecanada/" className='t12'>LinkedIn</a>
                            &nbsp; ·  &nbsp; 
                            <a href="/" className='t12'>Blog</a>
                        </p>
                        
                        
                    </div>
                    <div className="col-12  col-sm-3 align-self-center">
                        <p>@ 2020 EduShare</p>
                    </div>
                    <div className='col-12 col-sm-3 text-right'>
                        <p>Toronto Canada</p>
                    </div>
                </div>
        </div>
        <Contactus show={show} setShow={setShow}/>
        </>
    );
}

export default LandingPageFooter;

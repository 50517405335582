import React from 'react';
import LandingPageHeader from '../landingPageHeader'
import LandingPageFooter from '../landingPageFooter'
import TermsContent from './termsContent';

const Terms = () => {
    
    return(
        <>
        <LandingPageHeader/>
        <TermsContent/>
        <LandingPageFooter/>
        </>
    );
    
};

export default Terms;
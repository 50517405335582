import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import '../../css/LandingPageContent.css'
import teacherKid from '../../images/landingPage/Teacher_Kids 1.png'
import Curation from '../../images/landingPage/Curation_Icon.png'
import Collaboration from '../../images/landingPage/Collaboration_Icon.png'
import Community from '../../images/landingPage/Community_Icon.png'
import Evaluation from '../../images/landingPage/Evaluation_Icon.png'
import Grade9 from '../../images/landingPage/Grade9_Icon.png'
import Where from '../../images/landingPage/WhereDoWe_Illustration.png'
import Icon from '../../images/landingPage/DidYouKnow_Icon.png'
import Teachers from '../../images/landingPage/For Teachers_Icon.png'
import Students from '../../images/landingPage/ForStudents_Icon.png'
import Parents from '../../images/landingPage/ForParents_Icon.png'
import logo from '../../images/landingPage/EduShare_Logo_Homepage_Big.png'
import GetInformed from './Overlay/GetInformed'
import Subscribe from './Overlay/Subscribe'

const LandingPageContent = ({ changeView }) => {
    const [InformedShow, setInformedShow] = useState(false);
    const [SubscribeShow, setSubscribeShow] = useState(false);

    return (
        <div className="landingPageContent-outerContainer">

            <div className="landingPageContent-innerContainer">
                <div className="landingPage-text">
                    <p className='t1'>Welcome to EduShare</p>
                    <p className='t4'> Education through collaboration.</p>
                    <p className='t7'>
                        Our founder spent years teaching in Canadian classrooms. He experienced curriculum overhauls and shifting philosophies, but each September his students arrived less prepared than previous years. He realized the fundamental problem was a lack of study skills. EduShare provides students and their support systems with the fundamental skills, tools, and resources necessary for classroom success.
                    </p>
                    <div className="landingPageContent-buttonGroup">

                        <button onClick={() => changeView("student")}>I'm a student</button>
                        <button onClick={() => changeView("teacher")}>I'm a teacher</button>
                        <button onClick={() => changeView("parent")}>I'm a parent</button>
                    </div>
                </div>
                <div className="landingPageContent-rightContainer">
                    <img alt='img' src={teacherKid} height='360' />
                    <span className="picture-credited">Designed by Freepik</span>
                </div>
            </div>

            <div className="landingPageContent-second-outerContainer">
                <div className="landingPageContent-second-outerContainer-upper">
                    <p className='t4'>What Makes EduShare Unique</p>
                    <svg width="100" height="10px">
                        <line class="landingPageContent-second-line" x1="10" x2="500" y1="5" y2="5" />
                    </svg>
                </div>
                <div className="landingPageContent-second-outerContainer-middle">
                    <div className="landingPageContent-second-outerContainer-middle1">
                        <svg height="50px">
                            <text class="landingPageContent-second-t9" x="150" y="30">Curation</text>
                            <line class="landingPageContent-second-line" x1="135" x2="175" y1="40" y2="40" />
                        </svg>
                        <center>
                            <img alt="img" src={Curation} hspace="10" vspace="10" height="200" />
                        </center>
                        {/* credit */}
                        <span className="picture-credited">Designed by Freepik</span>
                        <p class="landingPageContent-second-t15">
                            EduShare will allow our community to share their knowledge in an organized way so that the information can be accessed easily and quickly.
                        </p>
                    </div>
                    <div className="landingPageContent-second-outerContainer-middle2">
                        <svg height="50px">
                            <text class='landingPageContent-second-t9' x="160" y="30">Collaboration</text>
                            <line class="landingPageContent-second-line" x1="140" x2="179" y1="40" y2="40" />
                        </svg>
                        <center><img alt="img" src={Collaboration} hspace="10" vspace="10" height="200" /></center>
                        {/* credit */}
                        <span className="picture-credited">Designed by Freepik</span>
                        <p class="landingPageContent-second-t15">
                            We believe collaboration is a key component of education, so our platform is built for teamwork.  At EduShare, teachers can work with other educators to build content.
                        </p>
                    </div>
                </div>
                <div className="landingPageContent-second-outerContainer-bottom">
                    <div className="landingPageContent-second-outerContainer-middle1">
                        <svg height="50px">
                            <text class='landingPageContent-second-t9' x="140" y="30">Community</text>
                            <line class="landingPageContent-second-line" x1="120" x2="170" y1="40" y2="40" />
                        </svg>
                        <center><img alt="img" src={Community} hspace="10" vspace="10" height="200" /></center>
                        {/* credit */}
                        <span className="picture-credited">Designed by Freepik</span>
                        <p class="landingPageContent-second-t15">
                            Educators, students and parents need to work together in order to build a strong educational community.  EduShare is the platform where all three parties can come together to learn, grow and work together.
                        </p>
                    </div>
                    <div className="landingPageContent-second-outerContainer-middle2">
                        <svg height="50px" >
                            <text class='landingPageContent-second-t9' x="140" y="30">Evaluation</text>
                            <line class="landingPageContent-second-line" x1="120" x2="170" y1="40" y2="40" />
                        </svg>
                        <center><img alt="img" src={Evaluation} hspace="10" vspace="10" height="200" /></center>
                        {/* credit */}
                        <span className="picture-credited">Designed by Freepik</span>
                        <p class="landingPageContent-second-t15">
                            EduShare’s platform will allow teachers and students to create quizzes and tests quickly and efficiently.  Analytics will be used to keep track of student progress.
                        </p>
                    </div>
                </div>
                <div className="landingPageContent-outerContainer-button">
                    <a href="./story">
                        <center><button >Learn More</button></center>
                    </a>

                </div>
            </div>

            <div className="landingPageContent-innerContainer">
                <div className="landingPage-text">
                    <div className='t2'>
                        Why is grade 9 math our starting place?
                            </div>
                    <p className='t14'>
                        Grade 9 Mathematics is a worry for many students and parents.  Making the transition from elementary to high school can be an intimidating experience.  At EduShare, we are working to overcome the Math anxiety that is felt by helping students with their basic Math skills and their study skills through lessons, videos, quizzes and games.
                            </p>
                    <div className="landingPageContent-buttonGroup">
                        <button id="t11" onClick={() => setSubscribeShow(true)}>Subscribe Now</button>
                    </div>
                </div>

                <div className="landingPageContent-rightContainer">
                    <img alt="img" src={Grade9} height='230' />
                    {/* credit */}
                    <span className="picture-credited">Designed by Freepik</span>
                </div>
            </div>

            <div className='container'>
                <div className='t2'>
                    <center><img alt="img" className='mr-2 mb-3' src={Icon} />Did you know?</center>

                </div>
                <div className='row justify-content-center mb-20'>
                    <div className='col-md-8 text-center'>
                        <p className='t14'>
                            COVID-19 has forced many teachers, students and parents to join the digital age.  It has been a struggle!  EduShare is looking to create a community that will work together during these trying times.  Join us today by subscribing!
                        </p>
                    </div>
                </div>
            </div>

            <div className="landingPageContent-innerContainer">
                <div className="landingPageContent-rightContainer">
                    <img alt="img" src={Where} height='300' />
                    {/* credit */}
                    <span className="picture-credited">Designed by Freepik</span>
                </div>
                <div className="landingPage-text">
                    <div className='t2'>
                        Where do we go from here?
                        </div>
                    <p className='t14'>
                        We are working with educators, students and parents to build a platform that will meet their needs.  Starting with Grade 9 Mathematics is a great starting point as it is a core subject that causes the most worry.
                        </p>
                    <div className="landingPageContent-buttonGroup">
                        <button id="t11">Subscribe</button>
                    </div>
                </div>
            </div>

            {/* teacher */}
            <div className="container">
                <center>
                    <p id="teacher" className='t4'>For Teachers</p>
                    <svg height="50px">
                        <line class="landingPageContent-second-line" x1="100" x2="200" y1="5" y2="5" />
                    </svg>
                </center>
                <div className="landingPageContent-innerContainer">
                    <div className="landingPage-text">
                        {/* <div className='t2'>
                                Cheesy Tagline
                            </div> */}
                        <p className='t14'>
                            Teachers spend countless hours trying to find resources and content for their courses.  Once they have the information, teachers take even more time to create quizzes, tests and assignments.  EduShare’s platform will help teachers by having curated content that can be easily accessed and tools to create various evaluations quickly and efficiently.
                            </p>
                        <div className="landingPageContent-buttonGroup">
                            <button id="t11" onClick={() => setInformedShow(true)}>Subscribe</button>
                        </div>
                    </div>
                    <div className="landingPageContent-rightContainer">
                        <img alt="img" src={Teachers} height='300' />
                        {/* credit */}
                        <span className="picture-credited">Designed by Freepik</span>
                    </div>
                </div>
            </div>
            {/* students */}
            <div className="container">
                <center>
                    <p id="student" className='t4'>For Students</p>
                    <svg height="50px" >
                        <line class="landingPageContent-second-line" x1="100" x2="200" y1="5" y2="5" />
                    </svg>
                </center>
                <div className="landingPageContent-innerContainer">
                    <div className="landingPageContent-rightContainer">
                        <img alt="img" src={Students} height='300' />
                        {/* credit */}
                        <span className="picture-credited">Designed by Freepik</span>
                    </div>
                    <div className="landingPage-text">
                        {/* <div className='t2'>
                                Cheesy Tagline
                            </div> */}
                        <p className='t14 top-50'>
                            On the EduShare platform, students will be able to learn various topics by accessing lessons, videos, quizzes, tests, assignments and many other ways to improve their knowledge.
                            </p>
                        <div className="landingPageContent-buttonGroup">
                            <button id="t11">Subscribe</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* parents */}
            <div className="container">
                <center>
                    <p id="parent" className='t4'>For Parents</p>
                    <svg height="50px" >
                        <line class="landingPageContent-second-line" x1="100" x2="200" y1="5" y2="5" />
                    </svg>
                </center>
                <div className="landingPageContent-innerContainer">
                    <div className="landingPage-text" >
                        {/* <div className='t2'>
                                Cheesy Tagline
                            </div> */}
                        <p className='t14'>
                            As a concerned parent, you want your child to have as many options as possible, and that all begins in the classroom. EduShare aims to give you confidence and peace of mind. Use our technology to monitor what your child is learning in the classroom, and to bring yourself up to speed. Generate personalized quizzes and tests to follow their progress and reinforce difficult concepts.
                            </p>
                        <div className="landingPageContent-buttonGroup">
                            <button id="t11" onClick={() => setInformedShow(true)}>Subscribe</button>
                        </div>
                    </div>
                    <div className="landingPageContent-rightContainer">
                        <img alt="img" src={Parents} height='300' />
                        {/* credit */}
                        <span className="picture-credited">Designed by Freepik</span>
                    </div>
                </div>
            </div>

            <div className='container'>
                <div>
                    <center><p className='t2'>Stay Up to Date</p></center>
                </div>
                <div className='row justify-content-center mb-50'>
                    <div className='col-md-8 text-center'>
                        <p className='t14'>
                            This is just the beginning!
                        </p>
                        <p className='t14'>
                            Subscribe now to join us as we grow our offering to support all students from kindergarten through high school.
                        </p>
                    </div>
                </div>
                <div className='row justify-content-center mt-50'>
                    <div className="landingPageContent-outerContainer-button">
                        <center><button onClick={() => setSubscribeShow(true)}>Subscribe</button></center>
                    </div>
                </div>
                <div className='row justify-content-center mt-50'>
                    <center><img alt='img' src={logo} height="200" /></center>
                </div>
            </div>

            <GetInformed show={InformedShow} setShow={setInformedShow} />
            <Subscribe show={SubscribeShow} setShow={setSubscribeShow} />

        </div>
    )
}

export default LandingPageContent
